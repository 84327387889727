import React, { useState, useEffect } from "react";
import ArrowLeft from "../utils/icons/ArrowLeft.svg";
import ArrowRight from "../utils/icons/ArrowRight.svg";

const Gallery = ({ property }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [widthPx, setWidthPx] = useState(380); // Nuevo estado para el ancho de desplazamiento

  // Función para abrir el modal
  const openModal = (img) => {
    setCurrentImage(img);
    setIsOpen(true);
  };

  // Función para cerrar el modal
  const closeModal = () => {
    setIsOpen(false);
    setCurrentImage(null);
  };

  // Calcular el widthPx según el ancho de la pantalla y actualizar en tiempo real
  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth < 640 ? window.innerWidth - 16 : 380;
      setWidthPx(newWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Funciones para desplazarse en la galería
  const scrollLeft = () =>
    (document.getElementById("content").scrollLeft -= widthPx);
  const scrollRight = () =>
    (document.getElementById("content").scrollLeft += widthPx);

  return (
    <div className="pt-20 md:mb-10">
      {/* Título */}
      <div className="min-[480px]:flex min-[480px]:items-center min-[480px]:justify-between">
        <h2 className="text-2xl font-semibold mb-4 md:mb-4 md:text-[1.875rem] lg:mb-4">
          Galeria
        </h2>
      </div>

      {/* Contenedor de imágenes */}
      <div className="pt-8 md:pt-10 lg:pt-20 md:pb-20 mx-[5vw] xl:mx-[1vw] mb-[30px]">
        <div
          id="content"
          className="snap-x p-1 flex items-center md:overflow-hidden justify-start overflow-x-auto scroll-smooth scrollbar-hide space-x-[10vw] sm:space-x-[3vw] lg:p-1 lg:space-x-[2vw]"
        >
          {property?.images && property.images.length > 0 ? (
            <>
              {/* Botón para desplazarse a la izquierda */}
              <button
                onClick={scrollLeft}
                className="absolute left-0 bg-primary rounded-full p-3 min-[480px]:p-[1.1rem] md:left-1 lg:left-[1vw] xl:p-5 xl:left-[8.2vw]"
              >
                <img className="h-5" src={ArrowLeft} alt="" />
              </button>

              {/* Imágenes de la galería */}
              {property.images.map((img, i) => (
                <div key={i} className="snap-center sm:snap-start">
                  <div className="w-[87vw] h-auto rounded-3xl min-[480px]:w-[24.375rem]">
                    <img
                      className="rounded-3xl cursor-pointer max-h-[450px] object-contain" // Añadido max-h-[450px] y object-contain para ajustar la altura máxima
                      src={img}
                      alt={`imagen ${i}`}
                      onClick={() => openModal(img)} // Abrir modal al hacer clic en la imagen
                    />
                  </div>
                </div>
              ))}

              {/* Botón para desplazarse a la derecha */}
              <button
                onClick={scrollRight}
                className="absolute right-0 bg-primary rounded-full p-3 min-[480px]:p-[1.1rem] md:right-1 lg:right-[1vw] xl:p-5 xl:right-[8.2vw]"
              >
                <img className="h-5" src={ArrowRight} alt="" />
              </button>
            </>
          ) : (
            <p>No hay más imágenes para mostrar</p>
          )}
        </div>
      </div>

      {/* Modal fullscreen */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50 overflow-auto" // Añadido overflow-auto para poder hacer scroll si es necesario
          onClick={closeModal} // Cerrar modal al hacer clic en el fondo
        >
          <div
            className="relative p-4 max-w-[90vw] max-h-[90vh]"
            onClick={(e) => e.stopPropagation()}
          >
            {" "}
            {/* Evitar cierre del modal al hacer clic en la imagen */}
            <button
              className="absolute top-4 right-4 text-white text-4xl md:text-5xl font-bold p-2 md:p-4"
              onClick={closeModal}
            >
              &times;
            </button>
            <img
              src={currentImage}
              alt="Imagen ampliada"
              className="max-h-full max-w-full rounded-lg" // Cambiado a max-h-full y max-w-full para mejor ajuste
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
