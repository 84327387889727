import React from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Environment from "../utils/icons/Environments.svg";
import Room from "../utils/icons/Room.svg";
import Bathroom from "../utils/icons/Bathroom.svg";
import Garage from "../utils/icons/Garage.svg";
import PinWhite from "../utils/icons/PinWhite.svg";
import usePropertiesPanel from "../hooks/usePropertiesPanel";
import Swal from "sweetalert2";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { Spinner } from "../commons/Spinner";
import getAreaIcon from "../utils/getAreaIcon";
import CustomSpinner from "../commons/customSpinner";
import Gallery from "./Galery";


const Property = () => {
  const { getPropertyById } = usePropertiesPanel();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [property, setProperty] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const queryInitialState = {
    clientName: "",
    email: "",
    phone: "",
    propertyId: id,
    resolved: false,
  };
  const [userQuery, setUserQuery] = useState(queryInitialState);
  const widthPx = window.innerWidth < 640 ? window.innerWidth - 8 : 380;
  const scrollLeft = () =>
    (document.getElementById("content").scrollLeft -= widthPx);
  const scrollRight = () =>
    (document.getElementById("content").scrollLeft += widthPx);

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        setLoading(true);
        const property = await getPropertyById(id);
        setProperty(property);
      } catch (error) {
        console.error("Error fetching property: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProperty();
  }, [id]);

  if (loading) {
    return (
      <div className="flex flex-col justify-center h-screen ">
        <CustomSpinner />
      </div>
    );
  }
  const handleChange = (e) =>
    setUserQuery({ ...userQuery, [e.target.name]: e.target.value });

  const validateField = () => {
    const { clientName, email, phone } = userQuery;
    if (!clientName.trim() || !email.trim() || !phone.trim()) {
      Swal.fire({
        title: "Datos faltantes",
        text: "Por favor, complete todos los campos.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!validateField()) {
      return;
    }
    try {
      const docRef = await addDoc(collection(db, "customerQueries"), {
        ...userQuery,
        timestamp: Date.now(),
      });
      await Swal.fire({
        title: "Gracias por dejar tu consulta.",
        text: "Un agente se pondra en contacto contigo lo antes posible.",
        icon: "success",
      });
      setUserQuery(queryInitialState);
      console.log("Property added with ID: ", docRef.id);
    } catch (err) {
      console.log("Property add Error:", err);
      await Swal.fire({
        title: "Lo sentimos, ocurrió un error",
        text: "Vuelva a intentarlo más tarde.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    property &&
    property.id && (
      <>
        <section className="h-100">
          <div>
            <div className="relative h-[73vh] md:h-screen lg:h-[76vh]">
              <img
                className="absolute object-cover w-full h-full rounded-b-3xl"
                src={property.images[0]}
                alt="portada"
              />
              <div className="absolute flex items-end w-full h-full bg-transparent backdrop-opacity-10 backdrop-invert bg-gradient-to-b from-transparent to-black/90 rounded-b-3xl">
                <div className="mx-[5vw] text-white font-dmSans my-10 xl:px-[5vw]">
                  <h3 className="text-[2rem] font-semibold mb-4 leading-10 min-[480px]:text-[2.375rem] md:text-5xl">
                    {property.title}
                  </h3>
                  <p className="flex items-center text-gray-100 md:text-lg lg:w-[57vw] xl:w-[40vw]">
                    <img className="h-5 mr-2" src={PinWhite} alt="" />
                    {`${property.city}, ${property.adress}`}
                  </p>
                  <div className="flex items-center mt-2 min-[480px]:mb-0">
                    <div className="pl-1 text-2xl font-semibold">
                      {property.currency === "ARG" ? "$" : "USD "}
                      {property.price}
                    </div>
                    <div
                      className={
                        property.operation === "rent"
                          ? "text-sm text-gray-400 ml-1"
                          : "hidden"
                      }
                    >
                      /mes
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-[5vw] font-dmSans lg:mx-[3vw] xl:px-[7vw]">
              <div className="lg:flex lg:space-x-5">
                <div className="lg:w-9/12">
                  <div className="space-y-4 py-16 min-[480px]:grid min-[480px]:grid-cols-2 min-[480px]:space-y-0 min-[480px]:gap-6 md:grid-cols-none md:flex md:gap-4">
                    {property.environments !== 0 && (
                      <div className="w-auto flex justify-center items-center border rounded-full p-[0.875rem] font-dmSans text-md mr-3 min-[480px]:mr-0 md:text-lg">
                        <img
                          className="w-5 mr-2 md:w-[1.375rem]"
                          src={Environment}
                          alt=""
                        />
                        {property.environments} Ambientes
                      </div>
                    )}
                    {property.rooms !== 0 && (
                      <div className="w-auto flex justify-center items-center border rounded-full p-[0.875rem] font-dmSans text-md mr-3 min-[480px]:mr-0 md:text-lg">
                        <img
                          className="w-5 mr-2 md:w-[1.375rem]"
                          src={Room}
                          alt=""
                        />
                        {property.rooms} Habitaciones
                      </div>
                    )}
                    {property.bathrooms !== 0 && (
                      <div className="w-auto flex justify-center items-center border rounded-full p-[0.875rem] font-dmSans text-md mr-3 min-[480px]:mr-0 md:text-lg">
                        <img
                          className="w-5 mr-2 md:w-[1.375rem]"
                          src={Bathroom}
                          alt=""
                        />
                        {property.bathrooms} Baños
                      </div>
                    )}
                    {property.garage !== 0 && (
                      <div className="w-auto flex justify-center items-center border rounded-full p-[0.875rem] font-dmSans text-md mr-3 min-[480px]:mr-0 md:text-lg">
                        <img
                          className="w-5 mr-2 md:w-[1.375rem]"
                          src={Garage}
                          alt=""
                        />
                        {property.garage} Garage
                      </div>
                    )}
                  </div>
                  <div>
                    <h2 className="text-2xl font-semibold mt-2 mb-4 min-[480px]:text-[1.625rem] md:text-[1.875rem] md:mb-8">
                      Sobre la propiedad
                    </h2>
                    <p className="mb-6 text-gray-600 md:text-lg">
                      {property.description}
                    </p>
                    <p className="mb-6 text-gray-600 md:text-lg">
                      En D'Williams nos esforzamos por ser líderes en el mercado
                      inmobiliario, nos enorgullece nuestro compromiso
                      inquebrantable con la excelencia y la innovación en el
                      ámbito inmobiliario. Nuestra filosofía se centra en la
                      expansión constante de nuestros servicios, conocimientos y
                      experiencia para ofrecer al mercado propuestas y
                      desarrollos que no solo cumplan, sino que superen las
                      expectativas de nuestros valiosos clientes.
                    </p>
                  </div>
                  <hr className="my-2 border-t" />
                  <div>
                    <h2 className="text-2xl font-semibold my-8 md:text-[1.875rem] md:my-12">
                      Areas
                    </h2>
                    <div className="grid grid-cols-2 gap-y-10 gap-x-4 mb-10 min-[480px]:grid-cols-3 md:gap-y-12 md:mb-16">
                      {property?.areas && property.areas.length > 0 ? (
                        property.areas.map((area) => (
                          <div className="flex items-center">
                            <img
                              className="h-[30px] mr-2"
                              src={getAreaIcon[area]}
                              alt=""
                            />
                            <p>{area}</p>
                          </div>
                        ))
                      ) : (
                        <p>No hay mas areas para mostrar</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="lg:w-5/12">
                  <div className="mb-10 lg:sticky lg:top-5 ">
                    <div className="w-full h-auto py-10 px-6 border shadow-card rounded-3xl bg-white min-[480px]:py-12 min-[480px]:px-10 sm:py-[3.125rem] sm:px-[2rem] lg:w-auto lg:-mt-[21.5625rem]">
                      <h2 className="text-[1.375rem] font-semibold mb-2">
                        Agendar un visita
                      </h2>
                      <p className="mb-5 text-gray-600 md:text-lg">
                        Un agente se pondra en contacto contigo para agendar una
                        visita. Por favor dejanos los siguientes datos:
                      </p>
                      <hr className="mt-8 mb-10 border-t " />
                      <div className="min-[480px]:space-x-2">
                        <div className="min-[480px]:flex min-[480px]:space-x-2">
                          <div className="w-full">
                            <label className="mb-1 font-semibold text-gray-700 font-dmSans">
                              Nombre y Apellido
                            </label>
                            <div className="mt-1">
                              <input
                                name="clientName"
                                type="text"
                                onChange={handleChange}
                                required
                                className="w-full py-[0.95rem] border border-gray-300 rounded-full font-dmSans text-gray-600 mb-4 shadow-button focus:ring-0 focus:border-primary"
                              />
                            </div>
                          </div>
                          <div className="w-full">
                            <label
                              htmlFor="time"
                              className="mb-3 font-semibold text-gray-700 font-dmSans"
                            >
                              Teléfono
                            </label>
                            <div className="mt-1">
                              <input
                                name="phone"
                                type="number"
                                onChange={handleChange}
                                required
                                className="w-full py-[0.95rem] border border-gray-300 rounded-full font-dmSans text-gray-600 mb-4 shadow-button focus:ring-0 focus:border-primary"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="w-full">
                          <label className="mb-3 font-semibold text-gray-700 font-dmSans">
                            Email
                          </label>
                          <div className="mt-1">
                            <input
                              id="text"
                              name="email"
                              type="text"
                              onChange={handleChange}
                              required
                              className="w-full py-[0.95rem] border border-gray-300 rounded-full font-dmSans text-gray-600 mb-4 shadow-button focus:ring-0 focus:border-primary"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center justify-center w-full my-5">
                        <hr className="w-1/2 border border-gray-300" />
                        <hr className="w-1/2 border border-gray-300" />
                      </div>
                      <button
                        className="block shadow-button bg-black w-full py-[1rem] px-6 rounded-full text-base font-dmSans text-white hover:bg-primary hover:text-white min-[480px]:w-full sm:w-full sm:px-6 sm:py-[1.127rem] md:text-lg md:py-[1.375rem] md:px-9"
                        href="/"
                        onClick={handleSubmit}
                      >
                        {isLoading ? <Spinner /> : "Consultar"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
             <Gallery property={property}/>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  );
};

export default Property;
