import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import usePropertiesPanel from "../hooks/usePropertiesPanel";
import useCategories from "../hooks/useCategories";
import Swal from "sweetalert2";
import UpdateImagesModal from "../commons/UpdateImagesModal";
import useLocationData from "../hooks/useLocationData";
import SelectSearch from "../commons/SelectSearch";
import { Spinner } from "../commons/Spinner";
import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import useAreas from "../hooks/useAreas";
const UpdateProperty = () => {
  const [propertyValues, setPropertyValues] = useState({
    title: "",
    adress: "",
    city: "",
    state: "",
    country: "Argentina",
    rooms: "",
    bathrooms: "",
    environments: "",
    garage: "",
    category: "",
    operation: "",
    price: "",
    available: "",
    description: "",
    images: [],
    featured: null,
    currency: "",
    location: "",
    areas: [],
  });
  console.log(propertyValues);
  const [originalValues, setOriginalValues] = useState({});
  const [changesMade, setChangesMade] = useState(false);
  const { updateProperty, getPropertyById, loading } = usePropertiesPanel();
  const { categories } = useCategories();
  const { areas } = useAreas();
  const {
    provinces,
    municipalities,
    localities,
    selectedProvince,
    selectedMunicipality,
    setSelectedProvince,
    setSelectedMunicipality,
    setSelectedLocation,
    selectedLocation,
  } = useLocationData();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAreas, setSelectedAreas] = useState([]);

  const handleCity = async (e) => {
    setChangesMade(true);
    setSelectedProvince(e.nombre);
    setSelectedMunicipality("");
    setSelectedLocation("");
    setPropertyValues({
      ...propertyValues,
      city: e.nombre,
      state: "",
      location: "",
    });
  };
  const handleState = async (e) => {
    setChangesMade(true);
    setSelectedMunicipality(e.nombre);
    setSelectedLocation("");
    setPropertyValues({ ...propertyValues, state: e.nombre, location: "" });
  };
  const handleLocation = async (e) => {
    setChangesMade(true);
    setSelectedLocation(e.nombre);
    setPropertyValues({ ...propertyValues, location: e.nombre });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const { id } = useParams();

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const property = await getPropertyById(id);
        setPropertyValues(property);
        setOriginalValues(property);
        setSelectedProvince(property.city);
        setSelectedMunicipality(property.state);
        setSelectedLocation(property.location);
      } catch (error) {
        console.error("Error fetching property: ", error);
      }
    };

    fetchProperty();
  }, [id]);

  const handleAreasChange = (selectedOptions) => {
    setChangesMade(true);
    setSelectedAreas(selectedOptions);
    setPropertyValues({
      ...propertyValues,
      areas: selectedOptions,
    });
  };

  const handleUpdateImages = (updatedImages) => {
    setPropertyValues((prevValues) => ({
      ...prevValues,
      images: updatedImages,
    }));
    setChangesMade(true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (originalValues[name] !== value) {
      setChangesMade(true);
    }

    setPropertyValues((prevValues) => ({
      ...prevValues,
      [name]: parseValue(e),
    }));
  };

  const parseValue = (event) => {
    const { name, value, type } = event.target;

    if (name === "available") {
      return value === "true";
    }

    if (type === "number") {
      const numericValue = parseInt(value, 10);
      if (isNaN(numericValue)) {
        return 0;
      }
      if (numericValue < 0) {
        Swal.fire({
          icon: "error",
          title: "Valor inválido",
          text: "No se puede ingresar un valor negativo.",
        });
        return propertyValues[name] || 0;
      }
      return numericValue;
    }
    return value;
  };

  const handleUpdateProperty = async () => {
    const requiredFields = [
      "title",
      "adress",
      "city",
      "state",
      "category",
      "operation",
      "price",
      "description",
      "currency",
      "location",
    ];
    const emptyFields = requiredFields.filter(
      (field) => !propertyValues[field]
    );
    if (emptyFields.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Hay campos vacíos",
      });
      return;
    }

    if (propertyValues.images.length === 0) {
      Swal.fire({
        icon: "warning",
        text: "Por favor, ingresa imágenes para luego mostrar esta propiedad",
      });
      return;
    }
    try {
      if (!changesMade) {
        await Swal.fire({
          title: "Sin cambios",
          text: "No se realizaron cambios. No se actualizará la propiedad.",
          icon: "info",
          confirmButtonText: "OK",
        });
        return;
      }
      const propertyUpdateData = propertyValues;
      if (
        propertyUpdateData.available === false &&
        propertyUpdateData.featured === true
      ) {
        propertyUpdateData.featured = false;
      }
      await updateProperty(id, propertyUpdateData);
      await Swal.fire({
        title: "¡Propiedad actualizada!",
        icon: "success",
        confirmButtonText: "OK",
      });
      setOriginalValues(propertyValues);
      setChangesMade(false);
    } catch (error) {
      await Swal.fire({
        title: "Error",
        text: "Hubo un problema al actualizar la propiedad. Inténtalo de nuevo.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  return (
    <>
      <section>
        <div>
          <div className="relative h-[73vh] min-[480px]:h-[80vh] md:h-[82vh] lg:h-[110vh]">
            <img
              className="absolute object-cover w-full h-full rounded-b-3xl"
              src={propertyValues.images[0]}
              alt=""
            />
            <div className="absolute flex items-center w-full h-full text-center bg-transparent backdrop-opacity-10 backdrop-invert bg-gradient-to-b from-transparent to-black/90 rounded-b-3xl lg:items-start">
              <div className="mx-[5vw] w-full text-white font-dmSans my-10 lg:mt-[40vh] xl:px-[5vw]">
                <h3 className="text-[3rem] font-semibold mb-4 leading-10 min-[480px]:text-[2.375rem] md:text-5xl lg:text-6xl">
                  Actualizar propiedad
                </h3>
              </div>
            </div>
          </div>
          <div className="mx-[5vw] lg:mx-[10vw] xl:mx-[17vw]">
            <div className="sticky mb-20 lg:sticky lg:top-5 lg:mb-32">
              <div
                className="w-full h-auto py-10 px-6 border shadow-card rounded-3xl bg-white
          -mt-36 min-[480px]:py-12 min-[480px]:px-10 sm:py-[3.125rem] sm:px-[2rem] lg:w-auto lg:-mt-[17rem] lg:px-16 lg:py-16 xl:-mt-[21rem]"
              >
                <form onSubmit={handleSubmit}>
                  <div className="min-[480px]:flex min-[480px]:space-x-2 md:space-x-4">
                    <div className="min-[480px]:w-full">
                      <div>
                        <label
                          htmlFor="title"
                          className="mb-3 font-semibold text-gray-700 font-dmSans"
                        >
                          Titulo
                        </label>
                        <div className="mt-1">
                          <input
                            id="title"
                            name="title"
                            type="text"
                            defaultValue={propertyValues.title}
                            onChange={handleChange}
                            required
                            className="w-full py-[0.95rem] border border-gray-300 rounded-full font-dmSans text-gray-600 mb-4 shadow-button focus:ring-0 focus:border-primary"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="min-[480px]:flex min-[480px]:space-x-2 md:space-x-4">
                    <div className="min-[480px]:w-full">
                      <div>
                        <label
                          htmlFor="city"
                          className="mb-3 font-semibold text-gray-700 font-dmSans"
                        >
                          Provincia
                        </label>
                        <SelectSearch
                          data={provinces}
                          selected={selectedProvince}
                          setSelected={handleCity}
                        />
                      </div>
                    </div>
                    <div className="min-[480px]:w-full">
                      <div>
                        <label
                          htmlFor="state"
                          className="mb-3 font-semibold text-gray-700 font-dmSans"
                        >
                          Municipio
                        </label>
                        <SelectSearch
                          data={municipalities}
                          selected={selectedMunicipality}
                          setSelected={handleState}
                        />
                      </div>
                    </div>
                    <div className="min-[480px]:w-full">
                      <div>
                        <label
                          htmlFor="location"
                          className="mb-3 font-semibold text-gray-700 font-dmSans"
                        >
                          Localidad
                        </label>
                        <SelectSearch
                          data={localities}
                          selected={selectedLocation}
                          setSelected={handleLocation}
                        />
                      </div>
                    </div>
                    <div className="min-[480px]:w-full">
                      <div>
                        <label
                          htmlFor="adress"
                          className="mb-3 font-semibold text-gray-700 font-dmSans"
                        >
                          Dirección
                        </label>
                        <div className="mt-1">
                          <input
                            id="adress"
                            name="adress"
                            value={propertyValues.adress}
                            type="text"
                            onChange={handleChange}
                            required
                            className="w-full py-[0.95rem] border border-gray-300 rounded-full font-dmSans text-gray-600 mb-4 shadow-button focus:ring-0 focus:border-primary"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="min-[480px]:flex min-[480px]:space-x-2 md:space-x-4">
                    <div className="min-[480px]:w-full">
                      <div>
                        <label
                          htmlFor="rooms"
                          className="mb-3 font-semibold text-gray-700 font-dmSans"
                        >
                          Habitaciones
                        </label>
                        <div className="mt-1">
                          <input
                            id="rooms"
                            name="rooms"
                            type="number"
                            min={0}
                            defaultValue={propertyValues.rooms}
                            onChange={handleChange}
                            className="w-full py-[0.95rem] border border-gray-300 rounded-full font-dmSans text-gray-600 mb-4 shadow-button focus:ring-0 focus:border-primary"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="min-[480px]:w-full">
                      <div>
                        <label
                          htmlFor="bathrooms"
                          className="mb-3 font-semibold text-gray-700 font-dmSans"
                        >
                          Baños
                        </label>
                        <div className="mt-1">
                          <input
                            id="bathrooms"
                            name="bathrooms"
                            type="number"
                            min={0}
                            defaultValue={propertyValues.bathrooms}
                            onChange={handleChange}
                            className="w-full py-[0.95rem] border border-gray-300 rounded-full font-dmSans text-gray-600 mb-4 shadow-button focus:ring-0 focus:border-primary"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="min-[480px]:w-full">
                      <div>
                        <label
                          htmlFor="environments"
                          className="mb-3 font-semibold text-gray-700 font-dmSans"
                        >
                          Ambientes
                        </label>
                        <div className="mt-1">
                          <input
                            id="environments"
                            name="environments"
                            type="number"
                            min={0}
                            defaultValue={propertyValues.environments}
                            onChange={handleChange}
                            className="w-full py-[0.95rem] border border-gray-300 rounded-full font-dmSans text-gray-600 mb-4 shadow-button focus:ring-0 focus:border-primary"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="min-[480px]:w-full">
                      <div>
                        <label
                          htmlFor="garage"
                          className="mb-3 font-semibold text-gray-700 font-dmSans"
                        >
                          Garage
                        </label>
                        <div className="mt-1">
                          <input
                            id="garage"
                            name="garage"
                            type="number"
                            min={0}
                            defaultValue={propertyValues.garage}
                            onChange={handleChange}
                            className="w-full py-[0.95rem] border border-gray-300 rounded-full font-dmSans text-gray-600 mb-4 shadow-button focus:ring-0 focus:border-primary"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="min-[480px]:w-full">
                      <div>
                        <label
                          htmlFor="area"
                          className="mb-3 font-semibold text-gray-700 font-dmSans"
                        >
                          Areas
                        </label>
                        <div className="mt-1">
                          <Listbox
                            value={propertyValues.areas}
                            onChange={handleAreasChange}
                            multiple
                          >
                            {({ open }) => (
                              <>
                                <div className="relative h-[56.38px] mb-4">
                                  <Listbox.Button className="w-full p-[0.95rem] border border-gray-300 rounded-full font-dmSans text-gray-600  shadow-button focus:ring-0 focus:border-primary h-full ">
                                    <span className="">
                                      {propertyValues.areas.map(
                                        (area, index) => (
                                          <span
                                            className="truncate "
                                            key={area}
                                          >
                                            {area}
                                            {index !==
                                              selectedAreas.length - 1 && ", "}
                                          </span>
                                        )
                                      )}
                                    </span>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                      <ChevronDownIcon className="w-5 h-5 text-gray-400" />
                                    </span>
                                  </Listbox.Button>

                                  <Listbox.Options className="absolute z-10 w-full mt-1 overflow-auto bg-white border border-gray-300 rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {areas.map((area) => (
                                      <Listbox.Option
                                        key={area.id}
                                        value={area.name}
                                        className={({ active }) =>
                                          `${
                                            active
                                              ? "text-white bg-primary"
                                              : "text-gray-900"
                                          } cursor-pointer select-none relative py-2 pl-3 pr-9`
                                        }
                                      >
                                        {({ selected, active }) => (
                                          <>
                                            <span
                                              className={`${
                                                selected
                                                  ? "font-semibold"
                                                  : "font-normal"
                                              } block truncate`}
                                            >
                                              {area.name}
                                            </span>
                                            {selected && (
                                              <span
                                                className={`${
                                                  active
                                                    ? "text-white"
                                                    : "text-primary"
                                                } absolute inset-y-0 right-0 flex items-center pr-4`}
                                              >
                                                <CheckIcon
                                                  className="w-5 h-5"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            )}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </div>
                              </>
                            )}
                          </Listbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="min-[480px]:flex min-[480px]:space-x-2 md:space-x-4">
                    <div className="min-[480px]:w-full">
                      <div>
                        <label
                          htmlFor="category"
                          className="mb-3 font-semibold text-gray-700 font-dmSans"
                        >
                          Categoria
                        </label>
                        <select
                          type="text"
                          name="category"
                          value={propertyValues.category}
                          id="category"
                          onChange={handleChange}
                          className="w-full py-[0.95rem] border border-gray-300 rounded-full font-dmSans text-gray-600 mb-4 shadow-button focus:ring-0 focus:border-primary"
                          required
                        >
                          {categories.map((category) => (
                            <option key={category.id} value={category.name}>
                              {category.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="min-[480px]:w-full">
                      <div>
                        <label
                          htmlFor="operation"
                          className="mb-3 font-semibold text-gray-700 font-dmSans"
                        >
                          Operación
                        </label>
                        <select
                          type="text"
                          name="operation"
                          id="operation"
                          value={propertyValues.operation}
                          onChange={handleChange}
                          className="w-full py-[0.95rem] border border-gray-300 rounded-full font-dmSans text-gray-600 mb-4 shadow-button focus:ring-0 focus:border-primary"
                          required
                        >
                          <option value="sale">Venta</option>
                          <option value="rent">Alquiler</option>
                        </select>
                      </div>
                    </div>
                    <div className="min-[480px]:w-full">
                      <div>
                        <label
                          htmlFor="available"
                          className="mb-3 font-semibold text-gray-700 font-dmSans"
                        >
                          Moneda
                        </label>
                        <select
                          type="text"
                          name="currency"
                          value={propertyValues.currency}
                          id="currency"
                          onChange={handleChange}
                          className="w-full py-[0.95rem] border border-gray-300 rounded-full font-dmSans text-gray-600 mb-4 shadow-button focus:ring-0 focus:border-primary"
                          required
                        >
                          <option value={"USD"}>USD</option>
                          <option value={"ARG"}>ARG</option>
                        </select>
                      </div>
                    </div>
                    <div className="min-[480px]:w-full">
                      <div>
                        <label
                          htmlFor="price"
                          className="mb-3 font-semibold text-gray-700 font-dmSans"
                        >
                          Precio
                        </label>
                        <div className="mt-1">
                          <input
                            id="price"
                            name="price"
                            type="number"
                            min={0}
                            defaultValue={propertyValues.price}
                            onChange={handleChange}
                            required
                            className="w-full py-[0.95rem] border border-gray-300 rounded-full font-dmSans text-gray-600 mb-4 shadow-button focus:ring-0 focus:border-primary"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="min-[480px]:w-full">
                      <div>
                        <label
                          htmlFor="available"
                          className="mb-3 font-semibold text-gray-700 font-dmSans"
                        >
                          Disponibilidad
                        </label>
                        <select
                          type="boolean"
                          name="available"
                          id="available"
                          value={propertyValues.available}
                          onChange={handleChange}
                          className="w-full py-[0.95rem] border border-gray-300 rounded-full font-dmSans px-3 text-gray-600 mb-4 shadow-button focus:ring-0 focus:border-primary"
                          required
                        >
                          <option value={true}>Disponible</option>
                          <option value={false}>No disponible</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="min-[480px]:w-full">
                    <div>
                      <label
                        htmlFor="description"
                        className="mb-3 font-semibold text-gray-700 font-dmSans"
                      >
                        Descripción
                      </label>
                      <div className="mt-1">
                        <textarea
                          id="description"
                          name="description"
                          type="text"
                          defaultValue={propertyValues.description}
                          onChange={handleChange}
                          required
                          className="w-full py-[0.95rem] border border-gray-300 rounded-md font-dmSans text-gray-600 mb-4 shadow-button focus:ring-0 focus:border-primary"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="min-[480px]:w-full">
                    <div>
                      <label
                        htmlFor="image"
                        className="mb-3 font-semibold text-gray-700 font-dmSans"
                      >
                        Imagen
                      </label>

                      <div className="mt-1">
                        <button
                          type="button"
                          className="w-full py-[0.95rem] border border-gray-300 rounded-full font-dmSans text-gray-600 mb-4 shadow-button focus:ring-0 focus:border-primary"
                          onClick={handleOpenModal}
                        >
                          Actualizar Imágenes
                        </button>
                        {isModalOpen && (
                          <UpdateImagesModal
                            images={propertyValues.images}
                            onUpdateImages={handleUpdateImages}
                            onClose={handleCloseModal}
                            setChangesMade={setChangesMade}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="sm:flex sm:space-x-2 sm:mt-4 sm:justify-center md:space-x-4 lg:mt-6">
                    <button
                      className="block shadow-button bg-primary w-full py-[1rem] px-6 rounded-full text-base font-dmSans text-white hover:bg-primary hover:text-white min-[480px]:w-full sm:w-auto sm:px-6 sm:py-[1.127rem] md:text-lg md:py-[1.375rem] md:px-9"
                      type="button"
                      onClick={handleUpdateProperty}
                    >
                      {loading ? <Spinner /> : "Actualizar"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpdateProperty;
